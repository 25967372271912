import client from '@/api/client'

export async function get () {
  const response = await client.get('/profile')
  return { profile: response.data }
}

export async function updatePreferredLocale ({ locale }) {
  const response = await client.put('/profile', { locale })
  return response.data
}

export async function leaveTeam ({ teamId }) {
  const response = await client.delete(`/profile/teams/${teamId}`)
  return response.data
}

export async function acceptTeamInvite ({ teamId, inviteId }) {
  const response = await client.post(`/profile/invites/${teamId}/accept?inviteId=${inviteId}`)
  return response.data
}

export async function rejectTeamInvite ({ teamId, inviteId }) {
  const response = await client.post(`/profile/invites/${teamId}/reject?inviteId=${inviteId}`)
  return response.data
}

export async function getPersonalAccessToken () {
  const response = await client.get('/profile/personalAccessToken')
  return response.data
}

export async function generatePersonalAccessToken () {
  const response = await client.post('/profile/personalAccessToken')
  return response.data
}

export async function revokePersonalAccessToken () {
  const response = await client.delete('/profile/personalAccessToken')
  return response.data
}

export default {
  get,
  updatePreferredLocale,
  leaveTeam,
  acceptTeamInvite,
  rejectTeamInvite,
  getPersonalAccessToken,
  generatePersonalAccessToken,
  revokePersonalAccessToken
}
