import store from '@/store'
import router from '@/router'

import { acceptTeamInvite, rejectTeamInvite } from '@/api/profile'
import { getApp } from '@/main'

// Helpers
export const isValidTeam = ({ teamId }) => {
  const { profile } = store.state
  return profile.teams.length > 0 && profile.teams.some(team => team.id === teamId)
}

export const isInvitedUser = ({ teamId, inviteId }) => {
  if (!store.state.profile?.invites) {
    return false
  }
  return store.state.profile.invites.some(invite => invite.id === inviteId && invite.teamId === teamId)
}

// Guards
const guardAcceptTeamInviteRoute = async (to, from, next) => {
  const teamId = to.params.teamId
  const app = getApp()

  if (to.query.inviteId) {
    const inviteId = to.query.inviteId

    if (isInvitedUser({ teamId, inviteId })) {
      try {
        await acceptTeamInvite({ teamId, inviteId })
        await store.dispatch('fetchProfile')
        app.sendNotification({ key: 'feedback-messages.invite.welcome', type: 'info', persist: true })
        if (Object.keys(to.query).length > 0) {
          router.replace({ query: {} })
        }
        next({ name: 'ListTemplatesPage', params: { teamId } })
      } catch (err) {
        app.notifyErrorWithKey(err, { persist: true })
        next({ name: 'UnauthorizedPage' })
      }
    } else {
      app.sendNotification({ key: 'feedback-messages.invite.invalid', type: 'danger', persist: true })
      next({ name: 'UnauthorizedPage' })
    }
  } else {
    next({ name: 'Homepage' })
  }
}

const guardRejectTeamInviteRoute = async (to, from, next) => {
  const teamId = to.params.teamId
  const app = getApp()

  if (to.query.inviteId) {
    const inviteId = to.query.inviteId

    if (isInvitedUser({ teamId, inviteId })) {
      try {
        await rejectTeamInvite({ teamId, inviteId })
        await store.dispatch('fetchProfile')
        app.sendNotification({ key: 'feedback-messages.invite.rejected', type: 'info', persist: true })
        if (Object.keys(to.query).length > 0) {
          router.replace({ query: {} })
        }
        next({ name: 'Homepage' })
      } catch (err) {
        app.notifyErrorWithKey(err, { persist: true })
        next({ name: 'UnauthorizedPage' })
      }
    } else {
      app.sendNotification({ key: 'feedback-messages.invite.invalid', type: 'danger', persist: true })
      next({ name: 'UnauthorizedPage' })
    }
  } else {
    next({ name: 'Homepage' })
  }
  store.dispatch('completeProgressBar')
}

export default {
  guardAcceptTeamInviteRoute,
  guardRejectTeamInviteRoute
}
