<template>
  <div>
    <router-view />
    <profile-settings-modal
      v-if="profileModalOpen"
      v-model="profileModalOpen"
      @close="setProfileModalOpen(false)"
    />
  </div>
</template>

<script>
import ProfileSettingsModal from '@/components/profile/ProfileSettingsModal.vue'
import { mapState, mapActions } from 'vuex'
export default {
  components: {
    ProfileSettingsModal
  },
  computed: {
    ...mapState(['profileModalOpen'])
  },
  methods: {
    ...mapActions(['setProfileModalOpen'])
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/resets";
@import "@/assets/scss/helpers";
@import "@/assets/scss/utilities";
@import "@/assets/scss/icons";
@import "@/assets/scss/typography";
@import "@/assets/scss/lists";
@import "@/assets/scss/cards";
@import "@/assets/scss/tables";
@import "@/assets/scss/buttons";
@import "@/assets/scss/labels";
@import "@/assets/scss/loading";
@import "@/assets/scss/avatars";
@import "@/assets/scss/empty-states";

// Global component styles
@import "@/assets/scss/tabs";
@import "@/assets/scss/forms";

// Global layout styles
@import "@/assets/scss/grid";
@import "@/assets/scss/layouts/layout-sidebar";
@import "@/assets/scss/layouts/layout-search-results";
@import "@/assets/scss/layouts/layout-wizard";

:root {
  --dark: #2e3242;
  --slate: #45555f;
  --gray: #717782;
  --gray400: #8f95a1;
  --blue: #009de0;
  --light-blue: #eef2f5;
  --magenta: #e9374f;
  --orange: #f9690e;
  --green: #199d5b;
}

body {
  background-color: $light-blue;
  overflow-x: hidden;
}
strong {
  font-weight: 600;
  color: $dark;
}
.scrollLock {
  overflow: hidden;
}
</style>
