import * as types from './mutation-types'
import { get as getProfile } from '@/api/profile'

export const fetchProfile = async ({ commit }) => {
  const response = await getProfile()
  commit(types.RECEIVE_PROFILE, { profile: response.profile })
}

export const setTopMessage = ({ commit }, { active, msg, dismiss, type, persist }) => {
  commit(types.SET_TOP_MESSAGE, { active, msg, dismiss, type, persist })
}

export const setProfileModalOpen = ({ commit }, profileModalOpen) => {
  commit(types.PROFILE_MODAL_OPEN, { profileModalOpen })
}

export const setProgressBar = ({ commit }, progress) => {
  commit(types.SET_PROGRESS_BAR_STATE, progress)
}

export const completeProgressBar = ({ commit }) => {
  commit(types.SET_PROGRESS_BAR_STATE, 100)
}
