import { createStore } from 'vuex'

import * as actions from './actions'
import mutations from './mutations'

// Vue.use(Vuex)

const store = createStore({
  state () {
    return {
      topMessage: { active: false, msg: { pt: ' ', en: '' }, dismiss: false, type: '', persist: false },
      profile: undefined,
      currentTeam: undefined,
      profileModalOpen: false,
      progressBar: 0
    }
  },
  actions,
  mutations
})

export default store
