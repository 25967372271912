<template>
  <h1 class="h4">
    {{ t('header') }}
  </h1>
  <p>{{ t('description') }}</p>
  <div class="btn-group confirm-generate-actions">
    <button
      class="btn btn--outlined"
      @click.prevent="isModalOpen = false"
    >
      {{ t('actions.cancel') }}
    </button>
    <button
      :disabled="isGenerating"
      :class="{ 'btn--disabled': isGenerating }"
      class="btn btn--primary"
      @click.prevent="$emit('confirm')"
    >
      {{ t('actions.confirm') }}
    </button>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'ConfirmGeneratePersonalAccessTokenModal',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    isGenerating: {
      type: Boolean,
      required: true
    }
  },
  emits: ['cancel', 'update:model-value', 'confirm'],
  setup () {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.modelValue
      },
      set (val) {
        if (!val) {
          this.$emit('cancel')
        }
        this.$emit('update:model-value', val)
      }
    }
  }
}
</script>

<i18n lang="yaml">
  pt:
    header: "Confirmar a geração de um novo token?"
    description: "Deseja mesmo gerar um novo token de acesso pessoal?"
    actions:
      cancel: "Cancelar"
      confirm: "Confirmar"
  en:
    header: "Confirm the generation of a new token?"
    description: "Do you really want to generate a new personal access token?"
    actions:
      cancel: "Cancel"
      confirm: "Confirm"

  </i18n>

<style lang="scss" scoped>
.confirm-generate-actions {
    margin-top: 2rem;
}
</style>
