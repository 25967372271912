import { getApp } from '@/main'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import statusTypes from '@/utils/templates/TemplateStatusTypes'

// Navigation guards
import guards, { isValidTeam } from './guards'

// API
import { getTemplate, getTemplates } from '@/api/templates'
import { getTeam, getTeamMembers } from '@/api/teams'

// Layouts
import PageWithNavBarAndFooterLayout from '@/layouts/PageWithNavBarAndFooterLayout'

// Pages
const LoginPage = () => import('@/pages/authentication/LoginPage.vue')
const CreateTeamPage = () => import('@/pages/teams/CreateTeamPage.vue')
const ManageTeamMembersPage = () => import('@/pages/teams/ManageTeamMembersPage.vue')
const ManageTeamSettingsPage = () => import('@/pages/teams/ManageTeamSettingsPage.vue')
const ListTemplatesPage = () => import('@/pages/templates/ListTemplatesPage.vue')
const EditTemplatePage = () => import('@/pages/templates/EditTemplatePage.vue')
const UnauthorizedPage = () => import('@/pages/UnauthorizedPage.vue')
const PageNotFoundPage = () => import('@/pages/PageNotFoundPage.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '',
      component: PageWithNavBarAndFooterLayout,
      children: [
        {
          path: '/unauthorized',
          name: 'UnauthorizedPage',
          component: UnauthorizedPage,
          meta: {
            public: true
          }
        },
        {
          path: '/',
          alias: '/teams',
          name: 'Homepage',
          beforeEnter: (to, from, next) => {
            if (store.state.profile && !store.state.profile.lastAccessedTeam) {
              return next({ name: 'CreateTeamPage' })
            } else {
              next({ name: 'ListTemplatesPage', params: { teamId: store.state.profile.lastAccessedTeam.id } })
            }
          }
        },
        {
          path: '/teams/:teamId',
          redirect: (route) => {
            const next = { name: 'ListTemplatesPage', params: { teamId: route.params.teamId } }
            if (route.query.inviteId) {
              return { ...next, query: { inviteId: route.query.inviteId } }
            }
            return next
          }
        },
        {
          path: '/teams/:teamId/templates',
          name: 'ListTemplatesPage',
          component: ListTemplatesPage,
          props: route => ({
            teamId: route.params.teamId,
            team: route.meta.team,
            templates: route.meta.templates,
            totalItems: route.meta.totalItems,
            totalActive: route.meta.totalActive,
            totalInactive: route.meta.totalInactive,
            query: route.query.q,
            status: route.query.status,
            page: route.meta.page,
            perPage: route.meta.perPage
          }),
          beforeEnter: async (to, from, next) => {
            const teamId = to.params.teamId

            // User does not belong to team nor has an invite id
            if (!isValidTeam({ teamId })) {
              return next({ name: 'UnauthorizedPage' })
            }

            // Validate status from query
            if (to.query.status && !statusTypes.includes(to.query.status)) {
              return next('/404')
            }

            // Default pagination items per page
            to.meta.perPage = 15
            to.meta.page = Number(to.query.page) || 1

            // Pre-fetch templates
            const { totalItems, items, totalActive, totalInactive } = await getTemplates({
              teamId: to.params.teamId,
              search: to.query.q,
              status: to.query.status,
              page: to.meta.page,
              perPage: to.meta.perPage
            })
            to.meta.templates = items
            to.meta.totalItems = totalItems
            to.meta.totalActive = totalActive
            to.meta.totalInactive = totalInactive
            next()
          }
        },
        {
          path: '/teams/:teamId/templates/:id',
          name: 'EditTemplatePage',
          component: EditTemplatePage,
          props: route => ({
            teamId: route.params.teamId,
            content: route.meta.content,
            isNew: route.meta.isNew,
            tab: route.query.tab
          }),
          async beforeEnter (to, from, next) {
            const teamId = to.params.teamId
            const templateId = to.params.id

            if (!isValidTeam({ teamId })) {
              return next({ name: 'UnauthorizedPage' })
            }

            // Pre-fetch template content & stringify json values.
            const template = await getTemplate({ teamId, templateId })
            Object.keys(template.translations).forEach(lang => {
              const { contexts, settings } = template.translations[lang]

              contexts.forEach((context, index) => {
                template.translations[lang].contexts[index].content = JSON.stringify(context.content, null, 2)
              })

              template.translations[lang].settings = JSON.stringify(settings, null, 2)
            })
            to.meta.content = template
            next()
          }
        },
        {
          path: '/teams/:teamId/manage',
          redirect: () => {
            return { name: 'ManageTeamMembersPage' }
          }
        },
        {
          path: '/teams/:teamId/manage/members',
          name: 'ManageTeamMembersPage',
          component: ManageTeamMembersPage,
          props: route => ({
            teamId: route.params.teamId,
            team: route.meta.team,
            members: route.meta.members,
            invitedMembers: route.meta.invitedMembers,
            query: route.query.q,
            type: route.query.type
          }),
          beforeEnter: async (to, from, next) => {
            const teamId = to.params.teamId

            // User does not belong to team nor has an invite id
            if (!(await isValidTeam({ teamId }))) {
              return next({ name: 'UnauthorizedPage' })
            }

            // Pre-fetch team info and members
            const teamPromise = getTeam({ teamId })
            const membersPromise = getTeamMembers({ teamId, search: to.query.q })
            const [team, { members, invitedMembers }] = await Promise.all([teamPromise, membersPromise])
            to.meta.team = team
            to.meta.members = members
            to.meta.invitedMembers = invitedMembers
            next()
          }
        },
        {
          path: '/teams/:teamId/manage/settings',
          name: 'ManageTeamSettingsPage',
          component: ManageTeamSettingsPage,
          props: route => ({
            teamId: route.params.teamId,
            team: route.meta.team
          }),
          beforeEnter: async (to, from, next) => {
            const teamId = to.params.teamId

            // User does not belong to team nor has an invite id
            if (!(await isValidTeam({ teamId }))) {
              return next({ name: 'UnauthorizedPage' })
            }

            // Pre-fetch team
            const team = await getTeam({ teamId })
            delete team.id
            to.meta.team = team
            next()
          }
        }
      ]
    },
    {
      path: '/create-team',
      name: 'CreateTeamPage',
      component: CreateTeamPage,
      beforeEnter (to, from, next) {
        const app = getApp()
        if (!app.$auth.isLogged()) {
          return next({ name: 'LoginPage' })
        }
        return next()
      }
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      meta: {
        public: true
      },
      async beforeEnter (to, from, next) {
        const app = getApp()
        if (app.$auth.isLogged()) {
          next({ name: 'Homepage' })
        } else {
          next()
        }
      }
    },
    {
      path: '/logout',
      name: 'Logout'
    },
    {
      path: '/profile/invites/:teamId/accept',
      name: 'AcceptTeamInviteRoute',
      beforeEnter: guards.guardAcceptTeamInviteRoute
    },
    {
      path: '/profile/invites/:teamId/reject',
      name: 'RejectTeamInviteRoute',
      beforeEnter: guards.guardRejectTeamInviteRoute
    },
    {
      path: '/:pathMatch(.*)',
      component: PageWithNavBarAndFooterLayout,
      children: [{
        path: '',
        name: 'PageNotFound',
        component: PageNotFoundPage
      }]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// Auth guard
router.beforeEach(async (to, from, next) => {
  await store.dispatch('setProgressBar', 10)

  const app = getApp()
  if (!hasPersistedNotification()) {
    app.clearNotification()
  }
  if (to.path === '/logout' && app.$auth.isLogged()) {
    app.$auth.logout()
    return
  }

  if (!to.meta.public) {
    if (!app.$auth.isLogged()) {
      if (to.path !== '/' && to.path !== '/logout') {
        return next({ name: 'LoginPage', query: { ...to.query, redirect: to.path } })
      } else {
        return next({ name: 'LoginPage' })
      }
    } else {
      const profilePromise = store.dispatch('fetchProfile')
      await store.dispatch('setProgressBar', 60)
      if (!store.state.profile) await profilePromise
      if (to.path === '/') {
        if (store.state.profile && !store.state.profile.lastAccessedTeam) {
          return next({ name: 'CreateTeamPage' })
        } else {
          return next({ name: 'ListTemplatesPage', params: { teamId: store.state.profile.lastAccessedTeam.id } })
        }
      } else {
        return next()
      }
    }
  } else if (to.name === 'LoginPage' || to.path === '/login') {
    if (app.$auth.isLogged()) {
      if (to.query.redirect) {
        if (to.query.inviteId) {
          next({ path: to.query.redirect, query: { inviteId: to.query.inviteId } })
        } else {
          next({ path: to.query.redirect })
        }
      } else {
        next({ name: 'Homepage' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(async (to, from) => {
  if (!from.name || (to.fullPath !== from.fullPath)) {
    await store.dispatch('completeProgressBar')
  }
})

const hasPersistedNotification = () => {
  if (!store.state.topMessage.active) {
    return false
  }
  return store.state.topMessage.persist
}

export default router
