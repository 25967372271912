import client from '@/api/client'

export async function createTeam ({ team }) {
  const response = await client.post('/teams', team)
  return response.data
}

export async function updateTeam ({ team, teamId }) {
  const response = await client.put(`/teams/${teamId}`, team)
  return response.data
}

export async function getTeam ({ teamId }) {
  const response = await client.get(`/teams/${teamId}`)
  return response.data
}

export async function getTeamMembers ({ teamId, search }) {
  const members = await client.get(`/teams/${teamId}/members?search=${search || ''}`)
  return members.data
}

export async function removeTeamMember ({ teamId, userId }) {
  const members = await client.delete(`/teams/${teamId}/members`, { data: { userId } })
  return members.data
}

export async function inviteUser ({ teamId, email }) {
  const members = await client.post(`/teams/${teamId}/members/invite`, { email })
  return members.data
}

export async function deleteInvite ({ teamId, userId, inviteId }) {
  const members = await client.delete(`/teams/${teamId}/members/invite?inviteId=${inviteId}`, { data: { userId } })
  return members.data
}

export default {
  createTeam,
  updateTeam,
  getTeam,
  getTeamMembers,
  removeTeamMember,
  inviteUser,
  deleteInvite
}
