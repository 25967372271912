<template>
  <h1 class="h4">
    {{ t('header') }}
  </h1>
  <p>{{ t('description') }}</p>
  <div class="btn-group confirm-revoke-actions">
    <button
      class="btn btn--outlined"
      @click.prevent="isModalOpen = false"
    >
      {{ t('actions.cancel') }}
    </button>
    <button
      :disabled="isDeleting"
      :class="{ 'btn--disabled': isDeleting }"
      class="btn btn--danger"
      @click.prevent="$emit('confirm')"
    >
      {{ t('actions.confirm') }}
    </button>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'ConfirmRevokePersonalAccessTokenModal',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    isDeleting: {
      type: Boolean,
      required: true
    }
  },
  emits: ['cancel', 'update:model-value', 'confirm'],
  setup () {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.modelValue
      },
      set (val) {
        if (!val) {
          this.$emit('cancel')
        }
        this.$emit('update:model-value', val)
      }
    }
  }
}
</script>

<i18n lang="yaml">
  pt:
    header: "Confirmar revogação do token?"
    description: "Revogar o token de acesso pessoal terá um impacto permanente. Deseja mesmo revogar o token?"
    actions:
      cancel: "Cancelar"
      confirm: "Confirmar"
  en:
    header: "Confirm token revocation?"
    description: "Revoking the personal access token will have a permanent impact. Do you really want to revoke the token?"
    actions:
      cancel: "Cancel"
      confirm: "Confirm"
</i18n>

<style lang="scss" scoped>
.confirm-revoke-actions {
    margin-top: 2rem;
}
</style>
