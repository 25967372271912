<template>
  <modal
    v-model="isModalOpen"
    layout-type="background-visible"
  >
    <template #modal-panel>
      <h1 class="h2">
        {{ t('header') }}
      </h1>
      <div class="personal-access-token">
        <h2 class="h3">
          {{ t('fields.accessToken.label') }}
        </h2>
        <template v-if="!isLoading">
          <p v-if="!personalAccessToken">
            {{ t('fields.accessToken.emptyState') }}
          </p>
          <template v-else>
            <p class="personal-access-token__created">
              {{ t('fields.accessToken.createdInstant', { date: format(personalAccessToken.created.when) }) }}
            </p>
            <div class="personal-access-token__info">
              <input
                :value="personalAccessToken.token"
                class="f-field__input personal-access-token__info-data"
                readonly
                type="text"
              >
              <button
                class="btn btn--danger btn--outlined btn--sm personal-access-token_info-revoke-button"
                :class="{'btn--disabled': isConfirming}"
                @click.prevent="prepareRevokePersonalAccessToken"
              >
                {{ t('actions.revoke') }}
              </button>
            </div>
          </template>
          <template v-if="isConfirmRevokePersonalAccessTokenOpen">
            <confirm-revoke-personal-access-token
              v-if="isConfirmRevokePersonalAccessTokenOpen"
              v-model="isConfirmRevokePersonalAccessTokenOpen"
              :is-revoking="isLoading"
              @confirm="revokePersonalAccessToken"
              @cancel="cancelRevokePersonalAccessToken"
            />
          </template>
          <template v-else-if="isConfirmGeneratePersonalAccessTokenOpen && !isLoading">
            <confirm-generate-personal-access-token
              v-if="isConfirmGeneratePersonalAccessTokenOpen"
              v-model="isConfirmGeneratePersonalAccessTokenOpen"
              :is-generaring="isLoading"
              @confirm="generatePersonalAccessToken"
              @cancel="cancelGeneratePersonalAccessToken"
            />
          </template>
        </template>
        <p v-else>
          {{ t('loading') }}
        </p>
      </div>
      <button
        v-if="!isLoading && !isConfirming"
        class="btn btn--primary"
        @click.prevent="prepareGeneratePersonalAccessToken"
      >
        {{ t('actions.generate') }}
      </button>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import ProfileAPI from '@/api/profile'
import Modal from '@/components/utils/Modal.vue'

import ConfirmGeneratePersonalAccessToken from './ConfirmGeneratePersonalAccessToken.vue'
import ConfirmRevokePersonalAccessToken from './ConfirmRevokePersonalAccessToken.vue'

export default {
  name: 'ProfileSettingsModal',
  components: {
    Modal,
    ConfirmGeneratePersonalAccessToken,
    ConfirmRevokePersonalAccessToken
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close'],
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      personalAccessToken: undefined,
      isLoading: false,
      isConfirmGeneratePersonalAccessTokenOpen: false,
      isConfirmRevokePersonalAccessTokenOpen: false,
      isConfirming: false
    }
  },
  computed: {
    ...mapState(['profile']),
    isModalOpen: {
      get () {
        return this.modelValue
      },
      set (val) {
        if (!val) {
          if (this.isConfirmGeneratePersonalAccessTokenOpen) this.cancelGeneratePersonalAccessToken()
          if (this.isConfirmRevokePersonalAccessTokenOpen) this.cancelRevokePersonalAccessToken()
        }
        this.setProfileModalOpen(val)
      }
    }
  },
  async created () {
    if (this.$auth.isLogged()) {
      await this.fetchPersonalAccessToken()
    }
  },
  methods: {
    ...mapActions(['setProfileModalOpen']),
    format (date) {
      return dayjs(date).locale(this.$i18n.locale).format('LL[ - ]HH:mm:ss')
    },
    async fetchPersonalAccessToken () {
      try {
        this.isLoading = true
        const result = await ProfileAPI.getPersonalAccessToken()
        if (result && result !== null) {
          this.personalAccessToken = result
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    prepareGeneratePersonalAccessToken () {
      this.isConfirmGeneratePersonalAccessTokenOpen = true
      this.isConfirming = true
    },
    async generatePersonalAccessToken () {
      try {
        this.isLoading = true
        this.personalAccessToken = await ProfileAPI.generatePersonalAccessToken()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
        this.cancelGeneratePersonalAccessToken()
      }
    },
    cancelGeneratePersonalAccessToken () {
      this.isConfirming = false
      this.isConfirmGeneratePersonalAccessTokenOpen = false
    },
    prepareRevokePersonalAccessToken () {
      this.isConfirmRevokePersonalAccessTokenOpen = true
      this.isConfirming = true
    },
    async revokePersonalAccessToken () {
      try {
        this.isLoading = true
        await ProfileAPI.revokePersonalAccessToken()
        this.personalAccessToken = undefined
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
        this.cancelRevokePersonalAccessToken()
      }
    },
    cancelRevokePersonalAccessToken () {
      this.isConfirming = false
      this.isConfirmRevokePersonalAccessTokenOpen = false
    },
    handleError (err) {
      this.notifyErrorWithKey(err)
      this.$emit('close')
    }
  }
}
</script>

<i18n lang="yaml">
pt:
  header: "Definições de perfil"
  fields:
    accessToken:
      label: "Token de acesso pessoal:"
      emptyState: "Ainda não tem um token de acesso pessoal."
      createdInstant: "Gerado a: {date}"
  loading: "A carregar…"
  actions:
    revoke: "Revogar"
    generate: "Gerar"
en:
  header: "Profile settings"
  fields:
    accessToken:
      label: "Personal access token:"
      emptyState: "You don't have a personal access token yet."
      createdInstant: "Generated on: {date}"
  loading: "Loading…"
  actions:
    revoke: "Revoke"
    generate: "Generate"
</i18n>

<style lang="scss" scoped>
.personal-access-token {
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.personal-access-token__info {
  position: relative;
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  margin-bottom: 0.5rem;

  .personal-access-token__info-data {
    margin-right: 0.45rem;
  }
}

.personal-access-token__created {
  font-size: 10pt;
}
</style>
