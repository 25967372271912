import { createApp } from 'vue'
import i18n, { setLocale } from '@/i18n'

import App from './App'
import store from './store'
import router from './router'
import VuePorg from 'vue-porg'
import client from '@/api/client'

import { Buffer } from 'buffer'

/* Dayjs imports and plugins */
import 'dayjs/locale/pt'
import 'dayjs/locale/en'

import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

import dayjs from 'dayjs'

dayjs.extend(CustomParseFormat)
dayjs.extend(LocalizedFormat)
/* ------------------------- */

window.addEventListener('offline', () => {
  app.sendNotification({ key: 'feedback-messages.error.network', type: 'warn', persist: true })
}, false)

window.addEventListener('online', () => {
  app.clearNotification()
}, false)

globalThis.Buffer = Buffer

function start () {
  const app = createApp(App).use(VuePorg, {
    router,
    store,
    axios: client,
    default2FAChallenge: 'U2FChallenge',
    notAuthorizedHandler: () => {
      alert('Not authorized')
    },
    notAuthorizedHandlerRoute: (auth, user) => {
      alert('Not authorized route')
    }
  })
    .use(router)
    .use(i18n)
    .use(store)
    .mixin({
      methods: {
        setLocale,
        sendNotification: function ({ key, message, params, type, persist }) {
          store.dispatch('setTopMessage', {
            active: true,
            msg: key
              ? {
                  pt: this.$t(key, params || {}, { locale: 'pt' }),
                  en: this.$t(key, params || {}, { locale: 'en' })
                }
              : message,
            dismiss: true,
            type,
            persist: persist || false
          })
        },
        clearNotification: function () {
          store.dispatch('setTopMessage', { active: false, msg: { pt: '', en: '' }, dismiss: false, type: '', persist: false })
        },
        notifyErrorWithKey: function (error, { type, persist } = {}) {
          if (error.key) {
            if (error.translations) {
              this.sendNotification({
                message: { pt: error.translations.pt, en: error.translations.en },
                type: type || 'danger',
                persist: persist || false
              })
            } else {
              this.sendNotification({ key: 'feedback-messages.error.internal', type: 'danger', persist: persist || false })
            }
          }
        }
      }
    })
    .mount('#app')
  return app
}

const app = start()

export function getApp () {
  return app
}
