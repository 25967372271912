import client from '@/api/client'
import { ACTIVE, INACTIVE } from '@/utils/templates/TemplateStatusTypes'

export async function getTemplates ({ teamId, search, status, page, perPage }) {
  const templates = await client.get(`/teams/${teamId}/templates`, {
    params: {
      ...(search && { search }),
      ...(status && { status }),
      ...(page !== undefined && perPage !== undefined && { skip: (page - 1) * perPage }),
      ...(page !== undefined && perPage !== undefined && { limit: perPage })
    }
  })
  return templates.data
}

export async function getTemplate ({ teamId, templateId }) {
  const template = await client.get(`/teams/${teamId}/templates/${templateId}`)
  return template.data
}

export async function createTemplate ({ teamId, template }) {
  const response = await client.post(`/teams/${teamId}/templates`, template)
  return response.data
}

export async function updateTemplate ({ teamId, templateId, newTemplate }) {
  const response = await client.put(`/teams/${teamId}/templates/${templateId}`, newTemplate)
  return response.data
}

export async function deactivateTemplate ({ teamId, templateId }) {
  const response = await client.put(`/teams/${teamId}/templates/${templateId}/status`, { status: INACTIVE })
  return response.data
}

export async function activateTemplate ({ teamId, templateId }) {
  const response = await client.put(`/teams/${teamId}/templates/${templateId}/status`, { status: ACTIVE })
  return response.data
}

export async function deleteTemplate ({ teamId, templateId }) {
  const response = await client.delete(`/teams/${teamId}/templates/${templateId}`)
  return response.data
}

export default {
  getTemplates,
  getTemplate,
  createTemplate,
  updateTemplate,
  deactivateTemplate,
  activateTemplate,
  deleteTemplate
}
